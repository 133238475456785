import React from 'react'
import AnimateLine from '../../../common/AnimateLine'
import Breadcrumb from '../../../common/Breadcrumb'
import ServiceAppWrap from './ServiceAppWrap'

function ServiceApp() {
  return (
    <>
        <div className="creasoft-wrap">
          <AnimateLine/>
          <Breadcrumb pageName="Aplikacje mobilne i webowe" pageTitle="Aplikacje mobilne i webowe"/>
          <ServiceAppWrap/>
        </div>
    </>
  )
}

export default ServiceApp