import React from "react";
import {Link} from "react-router-dom";
import "swiper/css/autoplay";
// import Swiper core and required modules
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, Navigation,} from "swiper";

SwiperCore.use([Navigation, Autoplay]);

function ServiceArea(props) {
    const scrollTop = () => window.scrollTo({top: 0, behavior: "smooth"});
    const serviceSlider = {
        loop: false,
        speed: 1000,
        autoplay: {
            delay: 30000,
            disableOnInteraction: false,
        },
        slidesPerView: 3,
        spaceBetween: 30,

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            }
        }
    }
    return (
        <>
            <section className={`${"services-area sec-mar"} ${props.layoutClass}`}>
                <div className="container">
                    <div
                        className="title-wrap wow animate fadeInUp"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                    >
                        <div className={`${"sec-title"} ${props.white}`}>
                            <span>Tworzenie Nowoczesnych Rozwiązań IT</span>
                            <h2>Usługi</h2>
                            <p>
                                Projektujemy dedykowane oprogramowanie, które odpowiada na indywidualne potrzeby Twojego
                                biznesu.
                            </p>
                        </div>
                    </div>
                    <Swiper {...serviceSlider} className="swiper services-slider">
                        <div className="swiper-wrapper">
                            <SwiperSlide
                                className="swiper-slide wow animate fadeInUp"
                                data-wow-delay="200ms"
                                data-wow-duration="1500ms"
                            >
                                <div className={`${"single-service"} ${props.layoutClass}`}>
                                    <div className="icon">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL + "/img/icons/service-icon-1.png"
                                            }
                                            alt="service-icon"
                                        />
                                    </div>
                                    <h4>Aplikacje mobilne i webowe</h4>
                                    <p>
                                        Specjalizujemy się w tworzeniu aplikacji mobilnych i webowych, które łączą
                                        nowoczesny design z niezawodną funkcjonalnością. Nasze aplikacje są dostosowane
                                        do potrzeb użytkowników, responsywne i zoptymalizowane pod kątem wydajności.
                                        Tworzymy rozwiązania o wysokiej jakości, które wspierają cele biznesowe naszych
                                        klientów oraz zapewniają wyjątkowe doświadczenie użytkownika na każdej
                                        platformie.
                                    </p>
                                    <div className="read-btn">
                                        <Link
                                            to={`${process.env.PUBLIC_URL}/service/apps`}
                                            onClick={scrollTop}
                                        >
                                            Więcej
                                        </Link>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide
                                className="swiper-slide wow animate fadeInUp"
                                data-wow-delay="400ms"
                                data-wow-duration="1500ms"
                            >
                                <div className={`${"single-service"} ${props.layoutClass}`}>
                                    <div className="icon">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL + "/img/icons/service-icon-2.png"
                                            }
                                            alt="service-icon"
                                        />
                                    </div>
                                    <h4>Strony internetowe</h4>
                                    <p>
                                        Projektujemy i tworzymy strony internetowe, które przyciągają uwagę i wzmacniają
                                        obecność online Twojej marki. Dbamy o nowoczesny design, responsywność i
                                        intuicyjną nawigację, aby zapewnić pozytywne doświadczenia użytkowników. Każda
                                        strona jest zoptymalizowana pod kątem wydajności i SEO, co wspiera widoczność
                                        oraz skuteczność działań marketingowych.
                                    </p>
                                    <div className="read-btn">
                                        <Link
                                            to={`${process.env.PUBLIC_URL}/service/webpages`}
                                            onClick={scrollTop}
                                        >
                                            Więcej
                                        </Link>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide
                                className="swiper-slide wow animate fadeInUp"
                                data-wow-delay="600ms"
                                data-wow-duration="1500ms"
                            >
                                <div className={`${"single-service"} ${props.layoutClass}`}>
                                    <div className="icon">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                                            }
                                            alt="service-icon"
                                        />
                                    </div>
                                    <h4>Systemy CRM i ERP</h4>
                                    <p>
                                        Oferujemy wdrożenie systemów CRM i ERP, które automatyzują procesy, poprawiają
                                        efektywność oraz wspierają zarządzanie relacjami z klientami i zasobami firmy.
                                        Nasze rozwiązania pozwalają na pełne dostosowanie do unikalnych potrzeb Twojego
                                        biznesu, zapewniając przejrzystość danych, lepsze planowanie i większą kontrolę
                                        nad operacjami. Dzięki nowoczesnym integracjom ułatwiają współpracę między
                                        działami i podnoszą jakość obsługi klienta.
                                    </p>
                                    <div className="read-btn">
                                        <Link
                                            to={`${process.env.PUBLIC_URL}/service/crm`}
                                            onClick={scrollTop}
                                        >
                                            Więcej
                                        </Link>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </div>
                    </Swiper>
                    <div className="swiper-button-next"/>
                    <div className="swiper-button-prev"/>
                </div>
            </section>
        </>
    );
}

export default ServiceArea;
