import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  const scrollTop=()=>window.scrollTo({top:0,behavior:"smooth"})
  return (
    <>
      <footer className={props.footerAddclass} >
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link scrollTo={scrollTop} to={"/"}>
                      <img src={process.env.PUBLIC_URL +"/img/logo.png"} alt="FooterImg" />
                    </Link>
                  </div>
                  {/*<ul className="social-media-icons">*/}
                  {/*  <li>*/}
                  {/*    <a href="https://www.facebook.com/">*/}
                  {/*      <i className="fab fa-facebook-f" />*/}
                  {/*    </a>*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*    <a href="https://www.twitter.com/">*/}
                  {/*      <i className="fab fa-twitter" />*/}
                  {/*    </a>*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*    <a href="https://www.pinterest.com/">*/}
                  {/*      <i className="fab fa-pinterest-p" />*/}
                  {/*    </a>*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*    <a href="https://www.instagram.com/">*/}
                  {/*      <i className="fab fa-instagram" />*/}
                  {/*    </a>*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Nasze usługi</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/service/apps`} onClick={scrollTop}>Aplikacje mobilne i webowe</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/service/webpages`} onClick={scrollTop}>Strony internetowe</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/service/crm`} onClick={scrollTop}>Systemy CRM - ERP</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Linki</h4>
                  <ul className="footer-menu">
                    {/*<li>*/}
                    {/*  <Link to={`${process.env.PUBLIC_URL}/about`} onClick={scrollTop}>O nas</Link>*/}
                    {/*</li>*/}
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/project`} onClick={scrollTop}>Projekty</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/blog`} onClick={scrollTop}>Technologie</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/blog`} onClick={scrollTop}>Kontakt</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                <h4>Kontakt</h4>
                  <div className="number">
                    <div className="num-icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="phone">
                      <a href="tel:513155500">513-155-500</a>
                    </div>
                  </div>
                  <div className="office-mail">
                    <div className="mail-icon">
                      <i className="far fa-envelope" />
                    </div>
                    <div className="email">
                      <a href="mailto:furcatomasz@gmail.com">furcatomasz@gmail.com</a>
                    </div>
                  </div>
                  {/*<div className="address">*/}
                  {/*  <div className="address-icon">*/}
                  {/*    <i className="fas fa-map-marker-alt" />*/}
                  {/*  </div>*/}
                  {/*  <p>61-160 Wiórek ul. Łąkowa 9b/1</p>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-12 col-md-4 col-lg-4 col-xl-5">
                <div className="copy-txt">
                  <span>
                    Copyright 2024 <b>WEBSTUDIO</b>
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                <ul className="footer-bottom-menu">
                  <li>
                    <Link to={"#"}>Polityka prywatności</Link>
                  </li>
                  <li>
                    <Link to={"#"}>Regulamin</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
