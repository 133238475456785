import React, {useReducer, useState} from "react";
import {Link} from "react-router-dom";
import data from "../../../data/data";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function ProjectCardFilter() {
    const [isOpen, setOpen] = useState(false)
    const [isLoaded, setLoaded] = useState(false)
    const [getModalId, setModalId] = useState(0)
    const [items, setItem] = useState(data);

    const filterItem = (catagoryitem) => {
        const updateItem = data.filter((curentelement) => {
            return curentelement.category === catagoryitem;
        });

        setItem(updateItem);
    };

    const initialState = {statementActive: ""};

    function reducer(state, action) {
        switch (action.type) {
            case "dataAll":
                setItem(data);
                return {statementActive: "dataAll"};
            case "Websites":
                filterItem("Websites");
                return {statementActive: "Websites"};
            case "Applications":
                filterItem("Applications");
                return {statementActive: "Applications"};
            case "Games":
                filterItem("Games");
                return {statementActive: "Games"};
            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);
    if (!isLoaded) {
        dispatch({type: "Websites"});
        setLoaded(true);
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ul className="isotope-menu">
                        <li
                            onClick={() => dispatch({type: "Websites"})}
                            data-filter=".Websites"
                            className={state.statementActive === "Websites" ? "active" : ""}
                        >
                            Strony internetowe
                        </li>
                        <li
                            onClick={() => dispatch({type: "Applications"})}
                            data-filter=".Applications"
                            className={state.statementActive === "Applications" ? "active" : ""}
                        >
                            Aplikacje
                        </li>
                        <li
                            onClick={() => dispatch({type: "Games"})}
                            data-filter=".Games"
                            className={state.statementActive === "Games" ? "active" : ""}
                        >
                            Gry
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row g-4 project-items d-flex">
                {items.map((element) => {
                    const {title, image, id, details, video, url} = element;
                    return (
                        <div key={id} className="col-12 single-item">
                            <div className="item-img">
                                {video ?
                                    <Link to={`#`} onClick={() => {
                                        setOpen(true);
                                        setModalId(id);
                                    }}>
                                        <img src={image} alt="images"/>
                                    </Link>
                                    : <a href={url} target={"_blank"}>
                                        <img src={image} alt="images"/>
                                    </a>
                                }
                            </div>
                            <div className="item-inner-cnt">
                                <h3>{title}</h3>
                                <p>{details}</p>
                            </div>
                            <React.Fragment>
                                <ModalVideo
                                    channel="youtube"
                                    isOpen={isOpen && id === getModalId}
                                    videoId={video}
                                    autoplay={1}
                                    onClose={() => setOpen(false)}
                                />
                            </React.Fragment>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default ProjectCardFilter;
