import React from 'react'
import AnimateLine from '../../../common/AnimateLine'
import Breadcrumb from '../../../common/Breadcrumb'
import ServiceDetailsWrap from './ServiceWebpageWrap'

function ServiceWebpage() {
  return (
    <>
        <div className="creasoft-wrap">
          <AnimateLine/>
          <Breadcrumb pageName="Strony internetowe" pageTitle="Strony internetowe"/>
          <ServiceDetailsWrap/>
        </div>
    </>
  )
}

export default ServiceWebpage