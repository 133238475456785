import React, {useEffect, useState} from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import HomePageOne from "../page/home/HomePageOne";

function MainLayout() {
    return (
        <>
            <Header/>
            <HomePageOne/>
            <Footer/>
        </>
    );
}

export default MainLayout;