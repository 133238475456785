import React from "react";

function ServiceAppWrap() {
    const scrollTop = () => window.scrollTo({top: 0, behavior: "smooth"})
    return (
        <>
            <div className="service-details sec-mar-top">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="service-details-content">
                                <h1>
                                    Aplikacje mobilne i webowe - Innowacyjne rozwiązania dla Twojego biznesu
                                </h1>
                                <p>
                                    Tworzymy aplikacje mobilne i webowe, które spełniają najwyższe standardy
                                    użyteczności i estetyki, dostosowane do potrzeb nowoczesnych użytkowników. Nasze
                                    aplikacje łączą dopracowany design z zaawansowaną funkcjonalnością, aby nie tylko
                                    przyciągać użytkowników, ale też efektywnie wspierać cele biznesowe naszych
                                    klientów. Dzięki pełnej optymalizacji gwarantujemy, że nasze aplikacje działają
                                    szybko i płynnie na każdej platformie, oferując wyjątkowe doświadczenia użytkownika.
                                </p>
                                <h5>Utrzymanie i Wsparcie</h5>
                                <p>
                                    Każdy projekt, który oddajemy w ręce klientów, obejmuje wsparcie techniczne oraz
                                    regularne aktualizacje, które gwarantują jego bezpieczeństwo oraz ciągłość
                                    działania.
                                    Dzięki temu możesz mieć pewność, że Twoja aplikacja jest niezawodna, zawsze aktualna
                                    i
                                    bezpieczna.
                                </p>

                                <h3>Proces Realizacji</h3>
                            </div>
                        </div>
                    </div>
                    <>
                        <div className="service-items sec-mar-top">
                            <div className="service-details-item">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="single-details">
                                            <h4><span>01</span>Analiza Potrzeb i Planowanie</h4>
                                            <p>Pierwszym krokiem jest konsultacja z klientem, podczas której dokładnie
                                                omawiamy
                                                funkcje i cele aplikacji. Wspólnie opracowujemy koncepcję, definiując
                                                styl
                                                wizualny i kluczowe funkcjonalności, aby aplikacja była zgodna z Twoją
                                                wizją
                                                oraz oczekiwaniami użytkowników.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="single-details-thumb">
                                            <img src={process.env.PUBLIC_URL + '/img/blog/thumb-1.webp'} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="service-details-item">
                                <div className="row">
                                    <div className="col-lg-6 or-2">
                                        <div className="single-details-thumb">
                                            <img src={process.env.PUBLIC_URL + '/img/blog/thumb-2.webp'} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="single-details">
                                            <h4><span>02</span>Tworzenie Prototypu i Projektu Graficznego</h4>
                                            <p>Następnie przystępujemy do projektowania wizualnego. Nasz zespół UI/UX
                                                designerów
                                                przygotowuje interaktywny prototyp, który pozwala zobaczyć układ i
                                                wygląd
                                                aplikacji. W tym etapie uwzględniamy wszelkie uwagi i nanosimy poprawki,
                                                aby
                                                finalny projekt graficzny w pełni odpowiadał na Twoje potrzeby i
                                                oczekiwania
                                                użytkowników.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="service-details-item">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="single-details">
                                            <h4><span>03</span>Programowanie i Wdrożenie</h4>
                                            <p>Kiedy projekt zostanie zatwierdzony, przekształcamy go w funkcjonalną
                                                aplikację.
                                                Nasi programiści dbają o wydajność, skalowalność i bezpieczeństwo, aby
                                                aplikacja
                                                działała płynnie, była odporna na awarie i spełniała wszystkie wymagania
                                                techniczne. W tym etapie realizujemy również testy, aby upewnić się, że
                                                wszystkie elementy aplikacji działają zgodnie z założeniami.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="single-details-thumb">
                                            <img src={process.env.PUBLIC_URL + '/img/blog/thumb-3.webp'} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="service-details-item">
                                <div className="row">
                                    <div className="col-lg-6 or-2">
                                        <div className="single-details-thumb">
                                            <img src={process.env.PUBLIC_URL + '/img/blog/thumb-4.webp'} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="single-details">
                                            <h4><span>04</span>Publikacja i Szkolenie</h4>
                                            <p>Po zakończeniu testów przeprowadzamy wdrożenie aplikacji na serwerze
                                                produkcyjnym
                                                oraz przeprowadzamy szkolenie, które pozwala na pełne korzystanie z
                                                nowego
                                                systemu. W ramach tego etapu udzielamy pełnego wsparcia, aby zapewnić,
                                                że
                                                aplikacja jest gotowa do działania i użytkowania.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </div>
            <br/>
            <br/>
        </>
    );
}

export default ServiceAppWrap;
