import React from 'react'

function AboutArea(props) {
    return (
        <>
            <section className="about-area sec-mar-bottom" id="about-us">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 or-2 wow animate fadeIn" data-wow-delay="200ms"
                             data-wow-duration="1500ms">
                            <div className={`${"sec-title layout2"} ${props.white}`}>
                                <h2>O nas</h2>
                            </div>
                            <div className={`${"about-left"} ${props.layoutClass}`}>
                                <h4>Projektujemy, kodujemy i rozwijamy oprogramowanie.</h4>
                                <p>Jesteśmy nowoczesnym software housem, który z pasją projektuje, koduje i wdraża
                                    innowacyjne rozwiązania programistyczne. Nasza praca to coś więcej niż tylko
                                    tworzenie kodu – każdy projekt zaczynamy od analizy potrzeb klienta, by zrozumieć
                                    jego cele i oczekiwania. Następnie opracowujemy koncepcje, które łączą estetykę z
                                    funkcjonalnością, zapewniając intuicyjne i stabilne rozwiązania.</p>
                                <br />
                                <p>Dbamy o każdy etap realizacji, od koncepcji po wdrożenie. Nasze podejście wyróżnia
                                    bliska współpraca z klientem, dzięki której dostarczamy oprogramowanie idealnie
                                    dopasowane do jego wizji i potrzeb biznesowych. Razem sprawiamy, że Twój projekt
                                    staje się rzeczywistością, wprowadzając na rynek wartościowe i trwałe innowacje.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 or-1 wow animate fadeIn" data-wow-delay="200ms"
                             data-wow-duration="1500ms">
                            <div className="about-right">
                                <div className="banner-1">
                                    <img src={process.env.PUBLIC_URL + '/img/about-banner-1.WEBP'} alt="about-images"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutArea