const data = [
    {
        id: 1,
        title: "Manage",
        image: "img/project/manage.png",
        category: "Websites",
        details: "Strona internetowa dla aplikacji Manage. Prezentuje ona kluczowe funkcje Manage i korzyści dla użytkowników, umożliwiając łatwą nawigację i szybkie zapoznanie się z możliwościami aplikacji.",
        url: "https://manage.com.pl/",
    },
    {
        id: 2,
        title: "Balma",
        image: "img/project/balma.png",
        category: "Websites",
        details: "Strona internetowa dla producenta mebli. Ponadto zaprojektowaliśmy i wdrożyliśmy system ERP, który wspiera zarówno dział produkcji, jak i handlowców. Dzięki temu systemowi, firma Balma zyskała narzędzie, które integruje i automatyzuje procesy. Efektem jest zwiększona efektywność i płynność działań, co przekłada się na lepszą obsługę klientów i sprawniejszy rozwój firmy.",
        url: "https://balma.pl/",
    },
    {
        id: 3,
        title: "MO-BAR",
        image: "img/project/mobar.png",
        category: "Websites",
        details: "Strona internetowa MO.BAR odzwierciedla unikalny klimat lokalu, łącząc funkcjonalność z estetyką, która zachęca do odwiedzin i rezerwacji.",
        url: "https://www.mo-bar.pl/",
    },
    {
        id: 7,
        title: "Marta Furca - PMU",
        image: "img/project/website-martafurca.png",
        category: "Websites",
        details: "Dla gabinetu kosmetycznego stworzyliśmy stronę internetową, która odzwierciedla wysoką jakość oferowanych usług. Strona prezentuje pełen zakres zabiegów w eleganckiej i nowoczesnej oprawie. Projekt kładzie nacisk na profesjonalizm i estetykę, zachęcając do rezerwacji wizyty oraz budując zaufanie do marki.",
        url: "https://martafurca.pl/",
    },

    {
        id: 8,
        title: "Manage - CRM",
        image: "img/project/app-manage.jpg",
        category: "Applications",
        details: "Manage to zaawansowana aplikacja internetowa oraz mobilna zaprojektowana, aby wspierać efektywne zarządzanie i administrację nieruchomościami. W procesie tworzenia postawiliśmy na atrakcyjny wizualnie, ale przede wszystkim funkcjonalny interfejs, który usprawnia codzienną pracę użytkowników. Dzięki technologiom Java i React, Manage umożliwia łatwe monitorowanie stanu nieruchomości, zarządzanie dokumentacją oraz organizację wszelkich działań administracyjnych. Aplikacja wspiera użytkowników w płynnej komunikacji, integrując różne elementy pracy w jednym miejscu i pozwalając na kreatywne podejście do zarządzania zasobami. Przemyślany design oraz intuicyjna obsługa sprawiają, że korzystanie z Manage jest wygodne i przyjemne, co w efekcie przekłada się na oszczędność czasu i większą wydajność zespołów zajmujących się nieruchomościami.",
        url: "https://crm.manage.com.pl/crm/login"
    },
    // {
    //     id:9,
    //     title:"Balma - ERP",
    //     image:"img/project/app-balma.png",
    //     category: "Applications",
    //     details:"Aplikacja ERP do zarządzania produkcją oraz reklamacjami.",
    // },

    {
        id: 6,
        title: "Knights",
        image: "img/project/knights.png",
        category: "Games",
        details: "Ta autorska gra przeglądarkowa, stworzona w 2004 roku na własnym silniku w PHP. Przenosi graczy do pełnego akcji świata, w którym tworzą własnych wojowników i walczą z potworami oraz innymi graczami. Dzięki emocjonującym turniejom, takim jak arena i koloseum, możesz zdobywać tytuły, budować reputację i walczyć o miano niekwestionowanego króla w grze. Pełna strategii, rywalizacji i ekscytujących wyborów – idealna propozycja dla pasjonatów gier online.",
        url: "https://knights.pl/",
    },
    {
        id: 5,
        title: "Slavs 2D",
        image: "img/project/game-slavs2d.png",
        category: "Games",
        details: "W tej epickiej grze stworzonej w technologii Unreal Engine, gracz wciela się w generała, który staje w obliczu wojny domowej w Lechii. Po śmierci poprzedniego władcy, Lech i jego brat Jaro wprowadzają brutalne porządki, zmuszając ludzi do buntu. Głód, zdrada i przemoc wywołują konflikt, który doprowadza do tragicznych decyzji. Gracz staje przed moralnym dylematem: wykonywać bezwzględne rozkazy władcy, czy stanąć po stronie ludzi. Decyzje podejmowane w grze prowadzą do nieuniknionych konsekwencji, gdy bohater staje przed Lechem jako zdrajca. Eksploracja, strategiczne bitwy i trudne wybory stanowią serce tej porywającej opowieści o wojnie, lojalności i zdradzie.",
        video: "6KP8UZqwOww",
    },
    {
        id: 4,
        title: "Slavs 3D",
        image: "img/project/game-slavs3d.png",
        category: "Games",
        details: "Ta gra RPG w klimacie słowiańskim, stworzona przy użyciu technologii WebGL i frameworka BabylonJS, przenosi graczy do świata pełnego wyzwań i przygód. Eksplorując różnorodne mapy, gracze mogą walczyć z potworami, wykonywać misje i zdobywać cenne wyposażenie. Projekt, dostępny jako open source, został udostępniony społeczności, by umożliwić dalszy rozwój i inspirację dla innych twórców zainteresowanych technologią WebGL.",
        video: "qVBpNhzhmsA",
    },


];

export default data;