import React from "react";

function TechnologyWrap() {
    const scrollTop = () => window.scrollTo({top: 0, behavior: "smooth"})
    return (
        <>
            <div className="service-details sec-mar-top">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="service-details-content">
                                <h1>
                                    Technologie, które inspirują i napędzają nasze projekty
                                </h1>
                                <p>
                                    W świecie dynamicznie rozwijających się technologii wyróżniamy się podejściem, które
                                    łączy solidne fundamenty programistyczne z najnowszymi innowacjami. Nasz zespół to
                                    pasjonaci technologii, którzy z zaangażowaniem śledzą trendy i rozwój narzędzi, aby
                                    dostarczać rozwiązania, które przewyższają oczekiwania. Pracujemy na szerokiej gamie
                                    technologii, które wzajemnie się uzupełniają, pozwalając nam realizować najbardziej
                                    ambitne projekty dla naszych klientów.
                                </p>
                                <h5>Backend i fundamenty: Java, PHP i Node.js</h5>
                                <p>
                                    Kiedy potrzebna jest stabilność i wysoka wydajność, bazujemy na Java, PHP oraz
                                    Node.js, które są podstawą rozbudowanych systemów backendowych. Dzięki ich mocy i
                                    elastyczności tworzymy niezawodne aplikacje serwerowe o dużej skalowalności, idealne
                                    dla rozwijających się firm.
                                </p>

                                <h5>Frontend i interfejsy użytkownika: React, JavaScript i HTML</h5>
                                <p>
                                    W każdej aplikacji niezwykle istotne jest, aby użytkownik końcowy miał wyjątkowe
                                    doświadczenie. Dlatego korzystamy z React i JavaScriptu, by tworzyć interfejsy,
                                    które są nie tylko estetyczne, ale także intuicyjne i szybkie. HTML to podstawa
                                    naszej pracy nad responsywnymi stronami, które płynnie działają na każdym
                                    urządzeniu.
                                </p>

                                <h5>Zaawansowane frameworki: Spring i Symfony</h5>
                                <p>
                                    Frameworki takie jak Spring i Symfony pomagają nam projektować złożone systemy
                                    oparte na zaawansowanej architekturze. Te narzędzia pozwalają nam tworzyć aplikacje
                                    biznesowe o wysokiej niezawodności, które rosną razem z potrzebami użytkowników i
                                    zachowują spójność oraz bezpieczeństwo.
                                </p>

                                <h5>Innowacje: Gemini i OpenAI</h5>
                                <p>
                                    Chcemy być w awangardzie technologii, dlatego wykorzystujemy możliwości sztucznej
                                    inteligencji i automatyzacji. Dzięki Gemini i OpenAI integrujemy inteligentne
                                    algorytmy, które otwierają przed klientami nowe perspektywy, automatyzując procesy i
                                    wzbogacając aplikacje o elementy predykcji oraz personalizacji.
                                </p>

                                <h5>Bazy danych: MongoDB i MySQL</h5>
                                <p>
                                    Solidne i szybkie bazy danych są kluczowe dla aplikacji zarządzających dużymi
                                    zasobami informacji. Stawiamy na MongoDB i MySQL, które umożliwiają nam efektywne
                                    zarządzanie danymi, zapewniając jednocześnie ich bezpieczeństwo i integralność.
                                </p>

                                <h5>Nowoczesne zarządzanie: Docker i Kubernetes</h5>
                                <p>
                                    Wdrażanie aplikacji w chmurze jest dziś standardem, który pozwala firmom na
                                    elastyczność i optymalizację kosztów. Dzięki Dockerowi i Kubernetesowi nasze
                                    aplikacje są gotowe na dowolną skalę, a ich wdrożenie jest szybkie i bezproblemowe.
                                </p>

                                <h3>Z nami na technologiczną przyszłość</h3>
                                <p>
                                    Dzięki wykorzystaniu tych narzędzi i technologii, tworzymy oprogramowanie, które nie
                                    tylko działa, ale także inspiruje i rozwija biznesy naszych klientów. Wierzymy, że
                                    odpowiednia technologia otwiera drzwi do nowych możliwości, a nasze podejście do
                                    projektowania i wdrażania rozwiązań zapewnia, że te drzwi pozostają szeroko otwarte.

                                    Każdy projekt realizujemy z myślą o długotrwałym sukcesie i rozwinięciu wizji
                                    naszych klientów. Wybierając nasze usługi, zyskujesz partnera, który przekształci
                                    Twoje pomysły w rzeczywistość, korzystając z najnowszych i najbardziej sprawdzonych
                                    narzędzi dostępnych na rynku. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
        </>
    );
}

export default TechnologyWrap;
