import React from "react";

function ServiceCrmWrap() {
    const scrollTop = () => window.scrollTo({top: 0, behavior: "smooth"})
    return (
        <>
            <div className="service-details sec-mar-top">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="service-details-content">
                                <h1>
                                    Systemy CRM i ERP - Efektywne zarządzanie Twoim biznesem
                                </h1>
                                <p>
                                    Naszym celem jest dostarczanie systemów CRM i ERP, które wspierają rozwój Twojej
                                    firmy, usprawniając zarządzanie procesami i relacjami z klientami. Projektujemy
                                    elastyczne, responsywne i intuicyjne rozwiązania, które doskonale wpisują się w
                                    specyfikę Twojego biznesu. Dzięki nowoczesnym technologiom i przemyślanemu
                                    designowi, nasze systemy są nie tylko funkcjonalne, ale także przyjazne
                                    użytkownikowi, co znacząco podnosi efektywność pracy zespołów na każdym poziomie.
                                </p>
                                <h5>Rozwój i Stałe Wsparcie</h5>
                                <p>Nasze systemy CRM i ERP są elastyczne, dlatego oferujemy dalszy rozwój oraz
                                    utrzymanie systemu, aby mógł ewoluować wraz z potrzebami Twojej firmy. Regularne
                                    aktualizacje i wsparcie techniczne gwarantują, że Twoje narzędzie zawsze będzie
                                    działać bez zarzutu, wspierając rozwój i sukces Twojej firmy.</p>
                                <h3>Proces Realizacji</h3>
                            </div>
                        </div>
                    </div>
                    <>
                        <div className="service-items sec-mar-top">
                            <div className="service-details-item">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="single-details">
                                            <h4><span>01</span>Analiza Potrzeb i Konsultacje</h4>
                                            <p>Prace nad systemem rozpoczynamy od szczegółowych konsultacji, podczas
                                                których zbieramy informacje na temat procesów i funkcji, które mają
                                                zostać zintegrowane. Rozmawiamy o Twoich celach i priorytetach, aby
                                                stworzyć system dopasowany do Twojego biznesu oraz zapewniający pełne
                                                wsparcie operacyjne.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="single-details-thumb">
                                            <img src={process.env.PUBLIC_URL + '/img/blog/thumb-1.webp'} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="service-details-item">
                                <div className="row">
                                    <div className="col-lg-6 or-2">
                                        <div className="single-details-thumb">
                                            <img src={process.env.PUBLIC_URL + '/img/blog/thumb-2.webp'} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="single-details">
                                            <h4><span>02</span>Projektowanie i Wizualizacja</h4>
                                            <p>Następnie nasi specjaliści od UI/UX tworzą wstępny projekt systemu,
                                                uwzględniający kluczowe moduły oraz układ interfejsu. Na tym etapie
                                                omawiamy szczegóły i wprowadzamy niezbędne poprawki, aby każdy element
                                                systemu był czytelny i łatwy w obsłudze. Nasze projekty łączą estetykę z
                                                ergonomią, oferując intuicyjny dostęp do funkcji oraz przejrzystą
                                                organizację danych.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="service-details-item">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="single-details">
                                            <h4><span>03</span>Programowanie i Implementacja Funkcji</h4>
                                            <p>Po zatwierdzeniu projektu przystępujemy do budowy systemu. Nasz zespół
                                                programistów opracowuje poszczególne moduły i integruje wszystkie
                                                funkcjonalności w jeden, spójny system CRM lub ERP. Dbamy o pełną
                                                wydajność i optymalizację, aby system działał szybko i niezawodnie,
                                                nawet przy intensywnym użytkowaniu.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="single-details-thumb">
                                            <img src={process.env.PUBLIC_URL + '/img/blog/thumb-3.webp'} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="service-details-item">
                                <div className="row">
                                    <div className="col-lg-6 or-2">
                                        <div className="single-details-thumb">
                                            <img src={process.env.PUBLIC_URL + '/img/blog/thumb-4.webp'} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="single-details">
                                            <h4><span>04</span>Wdrożenie i Szkolenie Użytkowników</h4>
                                            <p>Po przeprowadzeniu testów wdrażamy system na środowisko produkcyjne.
                                                Zespół przeprowadza szkolenie dla użytkowników, aby każdy mógł swobodnie
                                                korzystać z nowych funkcji. Oferujemy wsparcie na każdym etapie, co
                                                gwarantuje płynne przejście do nowego rozwiązania.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </div>
            <br/>
            <br/>
        </>
    );
}

export default ServiceCrmWrap;
