import React from 'react'
import CountUp from "react-countup";
function FeaturesArea() {
  
  return (
    <>
     <section className="features-area">
        <div className="container">
          <div className="title-wrap  wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
            <div className="sec-title white">
              <span>Co napędza nasze projekty?</span>
              <h2>Technologia</h2>
              <p>W naszych projektach stawiamy na sprawdzone i nowoczesne technologie. Dzięki nim tworzymy wydajne, skalowalne i bezpieczne rozwiązania, które pomagają firmom rozwijać się i dostosowywać do dynamicznie zmieniającego się rynku.</p>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-3 col-lg-3 col-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + "/img/icons/java.png"} alt="Feature-img"/>
                  </div>
                  <h4>Java</h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-6 wow animate fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + "/img/icons/react.png"} alt="Feature-img"/>
                  </div>
                  <h4>React JS</h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-6 wow animate fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + "/img/icons/php.png"} alt="Feature-img"/>
                  </div>
                  <h4>PHP</h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-6 wow animate fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + '/img/icons/javascript.png'} alt="Feature-img"/>
                  </div>
                  <h4>JavaScript</h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-6 wow animate fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + '/img/icons/html.png'} alt="Feature-img"/>
                  </div>
                  <h4>HTML</h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-6 wow animate fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + '/img/icons/nodejs.png'} alt="Feature-img"/>
                  </div>
                  <h4>NodeJs</h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-6 wow animate fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + '/img/icons/spring.png'} alt="Feature-img"/>
                  </div>
                  <h4>Spring</h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-6 wow animate fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + '/img/icons/symfony.png'} alt="Feature-img"/>
                  </div>
                  <h4>Symfony</h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-6 wow animate fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + '/img/icons/gemini.png'} alt="Feature-img"/>
                  </div>
                  <h4>Gemini</h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-6 wow animate fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + '/img/icons/openAI.png'} alt="Feature-img"/>
                  </div>
                  <h4>OpenAI</h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-6 wow animate fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + '/img/icons/mongodb.png'} alt="Feature-img"/>
                  </div>
                  <h4>MongoDB</h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-6 wow animate fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + '/img/icons/mysql.png'} alt="Feature-img"/>
                  </div>
                  <h4>MySQL</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-6 wow animate fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + '/img/icons/docker.png'} alt="Feature-img"/>
                  </div>
                  <h4>Docker</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-6 wow animate fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src={process.env.PUBLIC_URL + '/img/icons/kubernetes.png'} alt="Feature-img"/>
                  </div>
                  <h4>Kubernetes</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
     </section>
    </>
  )
}

export default FeaturesArea