import React from 'react'
import AnimateLine from '../../../common/AnimateLine'
import Breadcrumb from '../../../common/Breadcrumb'
import ServiceCrmWrap from './ServiceCrmWrap'

function ServiceCrm() {
  return (
    <>
        <div className="creasoft-wrap">
          <AnimateLine/>
          <Breadcrumb pageName="Systemy CRM i ERP" pageTitle="Systemy CRM i ERP"/>
          <ServiceCrmWrap/>
        </div>
    </>
  )
}

export default ServiceCrm