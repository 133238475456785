import React from "react";

function ServiceWebpageWrap() {
    const scrollTop = () => window.scrollTo({top: 0, behavior: "smooth"})
    return (
        <>
            <div className="service-details sec-mar-top">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="service-details-content">
                                <h1>
                                    Tworzenie Stron Internetowych - Od Wizji do Rzeczywistości
                                </h1>
                                <p>
                                    Naszą misją jest dostarczanie stron internetowych, które wyróżniają się na tle
                                    konkurencji, łącząc atrakcyjny design, zaawansowane funkcjonalności i pełną
                                    responsywność, aby Twoja marka mogła się doskonale prezentować na każdym urządzeniu.
                                    Tworzymy zarówno proste wizytówki, jak i rozbudowane strony internetowe z licznymi
                                    integracjami zewnętrznymi, odpowiadając na szerokie potrzeby współczesnych firm.
                                </p>
                                <h5>Łatwość Obsługi i Zarządzanie Treścią</h5>
                                <p>
                                    Każda z naszych stron internetowych powstaje na elastycznym systemie CMS, który
                                    pozwala klientowi w prosty sposób edytować treści i zdjęcia. Dzięki temu masz pełną
                                    kontrolę nad swoją stroną bez dodatkowych kosztów związanych z aktualizacjami –
                                    szybko, łatwo i intuicyjnie.
                                </p>
                                <h5>Dlaczego My?</h5>
                                <p>
                                    Wyróżnia nas zaangażowanie w każdy projekt oraz indywidualne podejście do klienta.
                                    Stawiamy na innowacyjne technologie i estetykę, które łączą się w spójnej i
                                    skutecznej formie, odpowiadając na potrzeby Twojego biznesu. Nasz zespół to
                                    doświadczeni profesjonaliści, których celem jest stworzenie strony internetowej,
                                    która nie tylko przyciągnie użytkowników, ale także spełni Twoje cele biznesowe.
                                </p>

                                <h3>Etapy współpracy</h3>
                            </div>
                        </div>
                    </div>
                    <>
                        <div className="service-items sec-mar-top">
                            <div className="service-details-item">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="single-details">
                                            <h4><span>01</span>Zrozumienie Twojej Wizji</h4>
                                            <p>Nasza współpraca zaczyna się od spotkania, na którym omawiamy Twoje
                                                potrzeby i cele. Wspólnie ustalamy styl strony, preferowaną kolorystykę,
                                                funkcje oraz wszystkie elementy, które sprawią, że witryna będzie
                                                skutecznym narzędziem dla Twojego biznesu. Wierzymy, że kluczem do
                                                sukcesu jest doskonała komunikacja i zrozumienie specyfiki Twojej
                                                marki.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="single-details-thumb">
                                            <img src={process.env.PUBLIC_URL + '/img/blog/thumb-1.webp'} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="service-details-item">
                                <div className="row">
                                    <div className="col-lg-6 or-2">
                                        <div className="single-details-thumb">
                                            <img src={process.env.PUBLIC_URL + '/img/blog/thumb-2.webp'} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="single-details">
                                            <h4><span>02</span>Projektowanie</h4>
                                            <p>Na podstawie zebranych informacji nasz UI/UX designer przygotuje projekt
                                                graficzny strony, który jest dopracowany w każdym detalu. Podczas tego
                                                etapu uwzględniamy Twoje sugestie, wprowadzając wszelkie niezbędne
                                                poprawki, aby finalny projekt idealnie odzwierciedlał Twoje oczekiwania.
                                                Projektowane przez nas strony są intuicyjne i funkcjonalne, skupiając
                                                się na doskonałych wrażeniach użytkownika.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="service-details-item">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="single-details">
                                            <h4><span>03</span>Realizacja i Programowanie</h4>
                                            <p>Gdy projekt zostanie zaakceptowany, przystępujemy do jego wdrożenia,
                                                przenosząc szablon graficzny na stronę internetową. Nasi programiści
                                                dbają o to, aby strona była szybka, bezpieczna i zoptymalizowana pod
                                                kątem wyszukiwarek, co zwiększa jej widoczność w sieci. Na każdym etapie
                                                realizacji zapewniamy pełną przejrzystość i informujemy Cię o postępach
                                                prac.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="single-details-thumb">
                                            <img src={process.env.PUBLIC_URL + '/img/blog/thumb-3.webp'} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="service-details-item">
                                <div className="row">
                                    <div className="col-lg-6 or-2">
                                        <div className="single-details-thumb">
                                            <img src={process.env.PUBLIC_URL + '/img/blog/thumb-4.webp'} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="single-details">
                                            <h4><span>04</span>Wdrożenie i Publikacja</h4>
                                            <p>Po zakończeniu prac nad stroną przeprowadzamy dokładne testy jakościowe,
                                                aby upewnić się, że wszystko działa bez zarzutu. Następnie wdrażamy
                                                stronę na serwer produkcyjny i podpinamy docelową domenę. Podczas
                                                oficjalnej prezentacji omawiamy sposób obsługi strony, zapewniając pełne
                                                wsparcie techniczne i odpowiadając na wszelkie pytania.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </div>
            <br/>
            <br/>
        </>
    );
}

export default ServiceWebpageWrap;
