import React from "react";
import ProjectCardFilter from "./ProjectCardFilter";

function ProjectWrap(props) {
    return (
        <>
            <div className="project-area sec-mar">
                <div className="container">
                    <div className="title-wrap">
                        <div className={`${"sec-title"} ${props.white}`}>
                            <h2>Projekty</h2>
                            <p>
                                Nasze projekty to wyjątkowe rozwiązania stworzone z pasją i zaangażowaniem. Każdy z nich
                                odzwierciedla naszą kreatywność oraz umiejętność dostosowania się do potrzeb klientów.
                                Dzięki innowacyjnym technologiom i współpracy z zespołem, osiągamy wyniki, które
                                przekraczają oczekiwania i wspierają rozwój biznesu.
                            </p>
                        </div>
                    </div>
                    <ProjectCardFilter/>
                </div>
            </div>
        </>
    );
}

export default ProjectWrap;
