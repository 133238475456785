import React from 'react'
import { Link } from 'react-router-dom'

function HeroArea() {
    const scrollTop =()=>window.scrollTo({top:0,behavior:"smooth"})
  return (
    <>
     <section className="hero-area">
        <div className="ken-burns-slideshow">
          <img src={process.env.PUBLIC_URL + "/img/hero-banner-1.WEBP"} alt="HeroBanner" />
        </div>
        {/*<div className="verticale-social">*/}
        {/*  <ul className="vertical-media">*/}
        {/*    <li><a href="https://www.facebook.com/">Facebook</a></li>*/}
        {/*    <li><a href="https://www.instagram.com/">Instagram</a></li>*/}
        {/*    <li><a href="https://www.linkedin.com/">Linkedin</a></li>*/}
        {/*  </ul>*/}
        {/*</div>*/}
        <div className="hero-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="hero-content">
                  <h1>WEBSTUDIO<span>Software House</span></h1>
                  <p>Zespół pasjonatów technologii, który tworzy dedykowane oprogramowanie dopasowane do Twoich unikalnych potrzeb. Specjalizujemy się w nowoczesnych rozwiązaniach, bazując na technologiach takich jak Java, PHP, React oraz sztucznej inteligencji, aby dostarczać innowacyjne i efektywne aplikacje dla biznesu. Nasze doświadczenie i zaangażowanie gwarantują, że każdy projekt realizujemy z najwyższą precyzją, dbając o detale i sukces naszych klientów. Zaufaj ekspertom, którzy łączą technologię z Twoją wizją!</p>
                  <div className="buttons">
                    <div className="cmn-btn">
                      <div className="line-1" />
                      <div className="line-2" />
                      <a href="#about-us">O nas</a>
                    </div>
                    <div className="cmn-btn layout-two">
                      <div className="line-1" />
                      <div className="line-2" />
                      <Link to={`${process.env.PUBLIC_URL}/project`} onClick={scrollTop}>Zobacz projekty</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default HeroArea