import React from 'react'
import AnimateLine from '../../common/AnimateLine'
import Breadcrumb from '../../common/Breadcrumb'
import TechnologyWrap from './TechnologyWrap'
import FeaturesArea from "../home/FeaturesArea";

function Technology() {
  return (
    <>
        <div className="creasoft-wrap">
          <AnimateLine/>
          <Breadcrumb pageName="Technologie" pageTitle="Technologie"/>
          <TechnologyWrap/>
          <FeaturesArea/>
        </div>
    </>
  )
}

export default Technology