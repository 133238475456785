import React from 'react'
import AnimateLine from '../../common/AnimateLine'
import ProjectWrap from '../project/ProjectWrap'
import TeamWrap from '../team/TeamWrap'
import AboutArea from './AboutArea'
import FeaturesArea from './FeaturesArea'
import HeroArea from './HeroArea'
import PartnarArea from './PartnarArea'
import ServiceArea from './ServiceArea'

function HomePageOne() {
    return (
        <>
            <div className="creasoft-wrap">
                <AnimateLine/>
                <HeroArea/>
                <ServiceArea/>
                <AboutArea image="/img/logo.png"/>
                <FeaturesArea/>
                <ProjectWrap/>
                <PartnarArea/>
                <TeamWrap/>
            </div>
        </>
    )
}

export default HomePageOne